








import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { NavigationGuardNext, Route } from "vue-router";

import SegmentForm from "@/segments/components/SegmentForm.vue";
import SegmentModel, {
  SEGMENT_TYPE_TO_APP_SECTION,
  SegmentType,
} from "@/segments/models/SegmentModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";

@Component({
  components: {
    SegmentForm,
  },
})
export default class NewSegmentView extends mixins(UnsavedChangesMixin) {
  @Prop() source?: SegmentModel;

  readonly APP_SECTION_TO_SEGMENT_TYPE: Record<string, SegmentType> =
    Object.entries(SEGMENT_TYPE_TO_APP_SECTION).reduce(
      (result, [key, value]) => Object.assign(result, { [value]: key }),
      {}
    );

  localSegment = new SegmentModel(
    this.applicationId,
    undefined,
    this.APP_SECTION_TO_SEGMENT_TYPE[this.$route.params.route]
  );

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return Object.assign({}, this.localSegment);
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  @Watch("source", { immediate: true })
  private watchSource(source?: SegmentModel) {
    if (source) {
      this.localSegment = SegmentModel.of(source, true);
      this.$store.commit("setUnsavedChanges", true);
    }

    this.loadSegmentUsedDictionaries();
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("segment.newTitle")
    );

    if (!this.hasUnsavedChanges) {
      this.handleWatchingUnsavedChanges();
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  async save() {
    if (this.localSegment.type === SegmentType.PROVIDED) {
      await this.$store.dispatch("createProvidedSegment", this.localSegment);
    } else {
      await this.$store.dispatch("createSegment", this.localSegment);
    }
    this.isSavedForm = true;
    this.$router.push({
      name: SEGMENT_TYPE_TO_APP_SECTION[this.localSegment.type],
      params: { id: this.applicationId },
    });
  }

  loadSegmentUsedDictionaries() {
    this.$store.dispatch("loadUsedDictionaries", {
      app: this.applicationId,
      values: this.localSegment.getUsedDictionaryValues(),
    });
    this.$store.dispatch("loadUsedTrackerDictionaries", {
      app: this.applicationId,
      values: this.localSegment.getUsedTrackerDictionaryValues(),
    });
  }
}
